import { useCallback, useEffect, useState } from 'react';
import {
  ContractType,
  MONGO_REALM_COLLECTIONS,
  Transaction,
  VetsuiteTransactions
} from '@setvi/shared/services';
import { enqueueSnackbar } from 'notistack';
import { StringParam, useQueryParam } from 'use-query-params';
import { useVetsuiteDigitalShowroomContext } from '../../../../providers/context';

const limit = 20;

export const useVetsuiteTransactions = () => {
  const { client, realmDbName, data, contractType } =
    useVetsuiteDigitalShowroomContext();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [hasMoreTransactions, setHasMoreTransactions] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customerId] = useQueryParam('customerId', StringParam);
  let page = 0;

  const fetchTransactions = useCallback(async () => {
    if (!hasMoreTransactions) return;
    setIsLoading(true);

    try {
      const [response]: VetsuiteTransactions[] = await client
        .db(realmDbName)
        .collection(MONGO_REALM_COLLECTIONS.VETSUITE_TRANSACTIONS)
        .aggregate([
          {
            $match: {
              covetrusId:
                contractType === ContractType.FULL_CONTRACT &&
                data?.account?.length
                  ? // eslint-disable-next-line no-underscore-dangle
                    { $in: data.account.map(account => account._id) }
                  : customerId
            }
          },
          { $sort: { date: -1 } },
          {
            $facet: {
              items: [{ $skip: page * limit }, { $limit: limit }],
              pagination: [
                {
                  $count: 'totalDocs'
                },
                {
                  $addFields: {
                    page: page + 1,
                    totalPages: {
                      $floor: {
                        $divide: ['$totalDocs', limit]
                      }
                    }
                  }
                }
              ]
            }
          }
        ]);

      page = response.pagination[0]?.page;

      setTransactions(preTransactions => [
        ...preTransactions,
        ...response.items
      ]);
      setHasMoreTransactions(response?.items?.length === limit);
    } catch (e) {
      enqueueSnackbar('Error occured while getting transaction data.', {
        variant: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  }, [client, realmDbName, customerId, hasMoreTransactions, contractType]);

  useEffect(() => {
    setTransactions([]);
    setHasMoreTransactions(true);
    fetchTransactions();
  }, [fetchTransactions, contractType]);

  return {
    transactions,
    isLoading,
    fetchTransactions
  };
};
