import { useEffect } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { VetsuiteOverview } from '@setvi/shared/services';
import { useDigitalShowroomContext } from '../../../../../../providers/app-provider/context';
import { useVetSuiteDigitalShowroomAnalytics } from '../../../../hooks';
import { Section } from '../../../../../../components/section';
import { useVetsuiteDigitalShowroomContext } from '../../../../providers/context';
import { Account, EnrollmentLevel } from '../../../../providers/types';
import {
  ListOfBenefits,
  PSIVetCoreBenefits,
  PSIVetSelectBenefits,
  PSIVetPremierBenefits,
  VMGCoreBenefits,
  VMGSelectBenefits,
  VMGPremierBenefits,
  IndependentCoreBenefits,
  IndependentSelectBenefits,
  IndependentPremierBenefits
} from '../../../../providers/benefits';
import { Program } from './program';
import { ProgramIcons } from '../../../../providers/requirements';
import { PerformanceRequirementProps } from './performance-requirement';
import { VetsuiteTabValue } from '../..';
import { ProgramAccordion } from './program-accordion';
import {
  getSoftwareUtilization,
  isRebateAvailable
} from '../utilities/covetrus-helpers';

const BenefitsTitle: Record<string, string> = {
  PSI: 'PSIvet Exclusive Benefits',
  VMG: 'VMG Exclusive Benefits',
  Independent: 'Independent Exclusive Benefits'
};

const BenefitsMap = {
  PSIVetCore: PSIVetCoreBenefits,
  PSIVetSelect: PSIVetSelectBenefits,
  PSIVetPremier: PSIVetPremierBenefits,
  VMGCore: VMGCoreBenefits,
  VMGSelect: VMGSelectBenefits,
  VMGPremier: VMGPremierBenefits,
  IndependentCore: IndependentCoreBenefits,
  IndependentSelect: IndependentSelectBenefits,
  IndependentPremier: IndependentPremierBenefits
};

interface ProgramDetailsProps {
  overview: VetsuiteOverview;
  setCurrentTab: (tab: string) => void;
}

export const ProgramDetails = ({
  setCurrentTab,
  overview
}: ProgramDetailsProps) => {
  const { allAccounts, customerId } = useVetsuiteDigitalShowroomContext();
  const { loggedInUserEmail } = useDigitalShowroomContext();
  const { captureAnalytics } = useVetSuiteDigitalShowroomAnalytics();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const targetAccountBenefits = allAccounts.find(
    acc =>
      acc.TypeOfCustomer === overview?.enrolmentType &&
      acc.EnrollmentLevel === overview?.enrolmentLevel
  );

  const [core, select, premier] = allAccounts.filter(
    ac => ac.TypeOfCustomer === overview?.enrolmentType
  );

  const distributionProgress = Math.round(
    (overview?.distributionYtd / overview?.distributionCommitmentYtd) * 100
  );
  const compoundingProgress = Math.round(
    (overview?.compoundingYtd / overview?.compoundingCommitmentYtd) * 100
  );

  const getListOfBenefits = (
    acc: Account,
    enrollmentLevelLookup: EnrollmentLevel
  ) => {
    const benefits = Object.entries(BenefitsMap).find(
      ([key]) =>
        key.includes(acc.TypeOfCustomer) && key.includes(enrollmentLevelLookup)
    )?.[1];

    let fixedRebateDVM;
    if (enrollmentLevelLookup === EnrollmentLevel.Core) {
      fixedRebateDVM = core.PerDVMRebate;
    }
    if (enrollmentLevelLookup === EnrollmentLevel.Select) {
      fixedRebateDVM = select.PerDVMRebate;
    }
    if (enrollmentLevelLookup === EnrollmentLevel.Premier) {
      fixedRebateDVM = premier.PerDVMRebate;
    }

    const rebateAmount = overview?.fte * fixedRebateDVM;

    const rebateBenefit = {
      icon: ProgramIcons.Dollar,
      title: `Rebate is ${fixedRebateDVM.toLocaleString('en-US', {
        currency: 'USD',
        style: 'currency',
        maximumFractionDigits: 0
      })} per DVM`,
      steps: [
        {
          completed: false,
          description: `Total rebate is ${rebateAmount.toLocaleString('en-US', {
            currency: 'USD',
            style: 'currency',
            maximumFractionDigits: 0
          })}`
        }
      ]
    };

    return [rebateBenefit, ...benefits];
  };

  const performanceRequirementsCore: PerformanceRequirementProps[] = [
    {
      title: 'Distribution Performance',
      icon: ProgramIcons.Truck,
      description: `Commitment Level is 70%`,
      currentProgress: distributionProgress,
      sliderProgress: 70,
      sliderProgressTitle: EnrollmentLevel.Core
    },
    {
      title: 'Compounding Performance',
      icon: ProgramIcons.Bowl,
      description: `Commitment Level is 70%`,
      currentProgress: compoundingProgress,
      sliderProgress: 70,
      sliderProgressTitle: EnrollmentLevel.Core
    }
  ];

  const performanceRequirementsSelect: PerformanceRequirementProps[] = [
    {
      title: 'Distribution Performance',
      icon: ProgramIcons.Truck,
      description: `Commitment Level is 80%`,
      currentProgress: distributionProgress,
      sliderProgress: 80,
      sliderProgressTitle: EnrollmentLevel.Select
    },
    {
      title: 'Compounding Performance',
      icon: ProgramIcons.Bowl,
      description: `Commitment Level is 80%`,
      currentProgress: compoundingProgress,
      sliderProgress: 80,
      sliderProgressTitle: EnrollmentLevel.Select
    }
  ];

  const performanceRequirementsPremier: PerformanceRequirementProps[] = [
    {
      title: 'Distribution Performance',
      icon: ProgramIcons.Truck,
      description: `Commitment Level is 90%`,
      currentProgress: distributionProgress,
      sliderProgress: 90,
      sliderProgressTitle: EnrollmentLevel.Premier
    },
    {
      title: 'Compounding Performance',
      icon: ProgramIcons.Bowl,
      description: `Commitment Level is 90%`,
      currentProgress: compoundingProgress,
      sliderProgress: 90,
      sliderProgressTitle: EnrollmentLevel.Premier
    }
  ];

  const benefits = [
    {
      icon: ProgramIcons.Dollar,
      title: `Rebate is ${overview?.fixedRebateDVM.toLocaleString('en-US', {
        currency: 'USD',
        style: 'currency',
        maximumFractionDigits: 0
      })} per DVM`,
      steps: [
        {
          completed: isRebateAvailable(
            overview,
            distributionProgress,
            compoundingProgress
          ),
          description: `Total rebate is ${(
            overview?.fixedRebateDVM * overview?.fte
          ).toLocaleString('en-US', {
            currency: 'USD',
            style: 'currency',
            maximumFractionDigits: 0
          })}`
        }
      ]
    },
    ...ListOfBenefits.filter(
      benefit => targetAccountBenefits.Benefits[benefit.key]
    ).map(bf => ({
      steps: [{ completed: true, description: bf.title }]
    }))
  ];

  const softwareUtilizationSelect = getSoftwareUtilization(
    EnrollmentLevel.Select,
    overview?.pims
  );

  const softwareUtilizationPremier = getSoftwareUtilization(
    EnrollmentLevel.Premier,
    overview?.pims
  );

  const Programs = [
    {
      name: `VetSuite \n (${overview?.enrolmentType} Core)`,
      estimatedTotalValue:
        overview?.enrolmentLevel === EnrollmentLevel.Core
          ? overview?.rebateAmmount
          : overview?.fte * core.PerDVMRebate + core.StaticRebate,
      isSelected: overview?.enrolmentLevel === EnrollmentLevel.Core,
      performanceRequirements: performanceRequirementsCore,
      benefitsTitle: BenefitsTitle[overview?.enrolmentType],
      benefits:
        overview.enrolmentLevel === EnrollmentLevel.Core
          ? benefits
          : getListOfBenefits(targetAccountBenefits, EnrollmentLevel.Core),
      techRequirements: [
        {
          icon: ProgramIcons.VrxPro,
          title: 'Fully Configured vRxPro',
          steps: [
            {
              completed: overview?.fullyConfigured,
              description: overview?.fullyConfigured
                ? 'Configured'
                : 'Not Configured'
            }
          ]
        }
      ]
    },
    {
      name: `VetSuite \n (${overview?.enrolmentType} Select)`,
      isSelected: overview?.enrolmentLevel === EnrollmentLevel.Select,
      estimatedTotalValue:
        overview?.enrolmentLevel === EnrollmentLevel.Select
          ? overview?.rebateAmmount
          : overview?.fte * select.PerDVMRebate + select.StaticRebate,
      performanceRequirements: performanceRequirementsSelect,
      benefitsTitle: BenefitsTitle[overview?.enrolmentType],
      benefits:
        overview?.enrolmentLevel === EnrollmentLevel.Select
          ? benefits
          : getListOfBenefits(targetAccountBenefits, EnrollmentLevel.Select),
      techRequirements: [
        {
          icon: ProgramIcons.VrxPro,
          title: 'Fully Configured vRxPro',
          steps: [
            {
              completed: overview?.fullyConfigured,
              description: overview?.fullyConfigured
                ? 'Configured'
                : 'Not Configured'
            }
          ]
        },
        {
          icon: ProgramIcons.Cloud,
          title: 'Covetrus Software Utilization',
          steps: [
            {
              completed: softwareUtilizationSelect.isCovetrusPIMS,
              description: softwareUtilizationSelect.name
            },
            {
              completed: overview?.communicationsSystem,
              description: 'Communications'
            },
            {
              completed: overview?.paymentsSystem,
              description: 'Payments'
            }
          ]
        }
      ],
      prefferedPartnerTitle: 'Dual-Choice Preferred Partner',
      prefferedPartner: [
        {
          steps: [
            {
              completed: overview?.enrolmentLevel === EnrollmentLevel.Select,
              description:
                overview?.enrolmentLevel === EnrollmentLevel.Select
                  ? 'Selected'
                  : 'Not Selected'
            }
          ]
        }
      ]
    },
    {
      name: `VetSuite \n (${overview?.enrolmentType} Premier)`,
      isSelected: overview?.enrolmentLevel === EnrollmentLevel.Premier,
      estimatedTotalValue:
        overview?.enrolmentLevel === EnrollmentLevel.Premier
          ? overview?.rebateAmmount
          : overview?.fte * premier.PerDVMRebate + premier.StaticRebate,
      performanceRequirements: performanceRequirementsPremier,
      benefitsTitle: BenefitsTitle[overview?.enrolmentType],
      benefits:
        overview?.enrolmentLevel === EnrollmentLevel.Premier
          ? benefits
          : getListOfBenefits(targetAccountBenefits, EnrollmentLevel.Premier),
      techRequirements: [
        {
          icon: ProgramIcons.VrxPro,
          title: 'Fully Configured vRxPro',
          steps: [
            {
              completed: overview?.fullyConfigured,
              description: overview?.fullyConfigured
                ? 'Configured'
                : 'Not Configured'
            }
          ]
        },
        {
          title: 'Covetrus Software Utilization',
          icon: ProgramIcons.Cloud,
          steps: [
            {
              completed: softwareUtilizationPremier.isCovetrusPIMS,
              description: softwareUtilizationPremier.name
            },
            {
              completed: overview?.communicationsSystem,
              description: 'Communications'
            },
            {
              completed: overview?.paymentsSystem,
              description: 'Payments'
            }
          ]
        }
      ],
      prefferedPartnerTitle: 'Single-Choice Preferred Partner',
      prefferedPartner: [
        {
          steps: [
            {
              completed: overview?.enrolmentLevel === EnrollmentLevel.Premier,
              description:
                overview?.enrolmentLevel === EnrollmentLevel.Premier
                  ? 'Selected'
                  : 'Not Selected'
            }
          ]
        }
      ],
      zoetisDiagnosticCommitment: [
        {
          icon: ProgramIcons.Zoetis,
          title: 'Zoetis Diagnostic Commitment',
          steps: [
            {
              completed: overview?.enrolmentLevel === EnrollmentLevel.Premier,
              description:
                overview?.enrolmentLevel === EnrollmentLevel.Premier
                  ? 'Selected'
                  : 'Not Selected'
            }
          ]
        }
      ]
    }
  ];

  let filteredPrograms = Programs;

  if (overview?.enrolmentLevel === EnrollmentLevel.Select) {
    filteredPrograms = Programs.filter(
      program =>
        program.name !== `VetSuite \n (${overview?.enrolmentType} Core)`
    );
  }

  if (overview?.enrolmentLevel === EnrollmentLevel.Premier) {
    filteredPrograms = Programs.filter(
      program =>
        program.name !== `VetSuite \n (${overview?.enrolmentType} Select)` &&
        program.name !== `VetSuite \n (${overview?.enrolmentType} Core)`
    );
  }

  useEffect(() => {
    if (loggedInUserEmail)
      captureAnalytics({
        actionType: 'ProgramDetailsTab',
        covetrusKey: customerId,
        timestamp: new Date(),
        userEmail: loggedInUserEmail
      });
  }, [captureAnalytics, customerId, loggedInUserEmail]);

  if (matches) {
    return (
      <Box>
        {filteredPrograms.map(program => (
          <ProgramAccordion
            key={program.name}
            programName={
              program.name.includes('Independent')
                ? program.name.replace('Independent ', '')
                : program.name
            }
            isProgramSelected={program.isSelected}
            benefitsTitle={program.benefitsTitle}
            benefits={program.benefits}
            techRequirements={program.techRequirements}
            estimatedTotalValue={program.estimatedTotalValue}
            prefferedPartnerTitle={program.prefferedPartnerTitle}
            performanceRequirements={program.performanceRequirements}
            prefferedPartner={program.prefferedPartner}
            useBulletPointsForBenefits={!program.isSelected}
            zoetisDiagnosticCommitment={program.zoetisDiagnosticCommitment}
          />
        ))}
      </Box>
    );
  }

  return (
    <Section>
      <Box display="flex" justifyContent="center" gap={4}>
        {filteredPrograms.map(program => (
          <Program
            key={program.name}
            programName={
              program.name.includes('Independent')
                ? program.name.replace('Independent ', '')
                : program.name
            }
            isProgramSelected={program.isSelected}
            benefitsTitle={program.benefitsTitle}
            benefits={program.benefits}
            techRequirements={program.techRequirements}
            estimatedTotalValue={program.estimatedTotalValue}
            prefferedPartnerTitle={program.prefferedPartnerTitle}
            performanceRequirements={program.performanceRequirements}
            prefferedPartner={program.prefferedPartner}
            zoetisDiagnosticCommitment={program.zoetisDiagnosticCommitment}
            useBulletPointsForBenefits={!program.isSelected}
            onContactAccountManager={() =>
              setCurrentTab(VetsuiteTabValue.CONTACT_US)
            }
          />
        ))}
      </Box>
    </Section>
  );
};
