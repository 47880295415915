import {
  VetsuiteOverview,
  PerformanceOverview,
  PurchaseType,
  ContractType
} from '@setvi/shared/services';
import { useCallback, useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { enqueueSnackbar } from 'notistack';
import { useVetsuiteDigitalShowroomContext } from '../../../../providers/context';

interface usePerformanceActivity {
  overview: VetsuiteOverview;
}

export const usePerformanceActivity = ({
  overview
}: usePerformanceActivity) => {
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [weeklyPerformance, setWeeklyPerformance] = useState<
    PerformanceOverview[]
  >([]);
  const { currentUser, data, contractType } =
    useVetsuiteDigitalShowroomContext();
  const [customerId] = useQueryParam('customerId', StringParam);

  const fetchWeeklyPerformance = useCallback(async () => {
    setIsloading(true);
    try {
      if (data?.contractId && contractType === ContractType.FULL_CONTRACT) {
        const result =
          await currentUser.functions.vetsuiteWeeklyPerformanceContract(
            data?.contractId,
            overview?.vetsuiteEnrollmentStartDate,
            [PurchaseType.Compounding, PurchaseType.Distribution],
            overview?.distributionCommitmentYtd,
            overview?.compoundingCommitmentYtd,
            overview?.commitmentLevel
          );
        return setWeeklyPerformance(result);
      }
      const result = await currentUser.functions.vetsuiteWeeklyPerformance(
        customerId,
        overview?.vetsuiteEnrollmentStartDate,
        [PurchaseType.Compounding, PurchaseType.Distribution],
        overview?.distributionCommitmentYtd,
        overview?.compoundingCommitmentYtd,
        overview?.commitmentLevel
      );

      setWeeklyPerformance(result);
    } catch (e) {
      enqueueSnackbar('Error occurred while getting performance data.', {
        variant: 'error'
      });
    } finally {
      setIsloading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overview, currentUser, customerId, contractType]);

  useEffect(() => {
    fetchWeeklyPerformance();
  }, [fetchWeeklyPerformance]);

  return {
    weeklyPerformance,
    isLoading
  };
};
